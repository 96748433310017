var render = function () {
  var _vm$report, _vm$report2, _vm$report3, _vm$report4, _vm$report5, _vm$report6, _vm$report7;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['report-card', ((_vm$report = _vm.report) === null || _vm$report === void 0 ? void 0 : _vm$report.type) === 'Individual' ? 'report-card--individual' : ''],
    on: {
      "click": _vm.handlePreview
    }
  }, [_c('div', {
    staticClass: "report-card__inner"
  }, [_c('div', {
    staticClass: "report-card__info"
  }, [_c('div', {
    staticClass: "report-card__info-flex"
  }, [_c('p', {
    staticClass: "report-card__description"
  }, [_vm._v(_vm._s((_vm$report2 = _vm.report) === null || _vm$report2 === void 0 ? void 0 : _vm$report2.source))]), _c('GenproxBadge', {
    attrs: {
      "circle": true,
      "outline": true,
      "variant": ((_vm$report3 = _vm.report) === null || _vm$report3 === void 0 ? void 0 : _vm$report3.type) === 'General' ? 'gn-primary' : 'orange',
      "text": (_vm$report4 = _vm.report) === null || _vm$report4 === void 0 ? void 0 : _vm$report4.type
    }
  })], 1), _c('p', {
    staticClass: "report-card__name"
  }, [_vm._v(_vm._s((_vm$report5 = _vm.report) === null || _vm$report5 === void 0 ? void 0 : _vm$report5.name))]), _c('p', {
    staticClass: "report-card__description",
    domProps: {
      "innerHTML": _vm._s((_vm$report6 = _vm.report) === null || _vm$report6 === void 0 ? void 0 : _vm$report6.description)
    }
  })]), _c('div', {
    staticClass: "report-card__footer"
  }, [_c('div', {
    staticClass: "report-card__actions"
  }, [_c('GenproxButton', [_vm._v("Show report")]), ((_vm$report7 = _vm.report) === null || _vm$report7 === void 0 ? void 0 : _vm$report7.source) !== 'Dynamic' ? _c('GenproxButton', {
    attrs: {
      "icon": _vm.CloudArrowDownIcon
    },
    nativeOn: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.handleDownload.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }